import { default as indexyKh4bHokVZMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/index.vue?macro=true";
import { default as _91id_93jMuvvQiDc7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundle-choices/[id].vue?macro=true";
import { default as index910pVevwTWMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundle-choices/index.vue?macro=true";
import { default as _91id_93ILzxj8dYMjMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundles/[id].vue?macro=true";
import { default as indexUd8GhpK5fcMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundles/index.vue?macro=true";
import { default as _91menuCategoryId_93bTlle4PlJCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/[id]/categories/[menuCategoryId].vue?macro=true";
import { default as indexQCma6Lg4IeMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/[id]/index.vue?macro=true";
import { default as indexgjKT4YNdPOMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/index.vue?macro=true";
import { default as indexpGyezWMjLEMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/index.vue?macro=true";
import { default as indexC5Msi8Q3BXMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id]/index.vue?macro=true";
import { default as _91modifierId_93rlNjfl6M0BMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue?macro=true";
import { default as _91id_93at298mGUCDMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id].vue?macro=true";
import { default as indexk04uWqjtskMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/index.vue?macro=true";
import { default as indexoT76ljgKpyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/index.vue?macro=true";
import { default as _91id_93dAFY0cUbT4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/product-categories/[id].vue?macro=true";
import { default as indexr3JfTf00m9Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/product-categories/index.vue?macro=true";
import { default as _91id_93XHsFz7WQhAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/products/[id].vue?macro=true";
import { default as indexRtbrZ2VpsTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/products/index.vue?macro=true";
import { default as merchantsC4CeGX6LOyMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/merchants.vue?macro=true";
import { default as _91accountId_93ISorh5SQDhMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue?macro=true";
import { default as indexDVp7Rn8mO6Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id]/index.vue?macro=true";
import { default as _91id_933Hm9SVYmhRMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id].vue?macro=true";
import { default as indexLghGOwhHvYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/index.vue?macro=true";
import { default as credentialsGIHAvYX496Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id]/credentials.vue?macro=true";
import { default as indexNwKt6ivIlYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id]/index.vue?macro=true";
import { default as _91id_93a6TaxShE72Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id].vue?macro=true";
import { default as _91driver_93ZnQG0ulptaMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/create/[driver].vue?macro=true";
import { default as index4QqlryaKs8Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/create/index.vue?macro=true";
import { default as indexKFouvk9crAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/index.vue?macro=true";
import { default as _91id_93KLI3DrK4yAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/discounts/[id].vue?macro=true";
import { default as indexemkAGUv5bPMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/discounts/index.vue?macro=true";
import { default as indexaKnud2mwELMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/index.vue?macro=true";
import { default as indexuU1OGeQugTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id]/index.vue?macro=true";
import { default as log4mRh67S5EpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id]/log.vue?macro=true";
import { default as _91id_93GHaKE8FF0GMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id].vue?macro=true";
import { default as indexCZVflzoOKiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/index.vue?macro=true";
import { default as indexjRkyoTnfruMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/images/index.vue?macro=true";
import { default as indexuUmBVdt5YVMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/payments/index.vue?macro=true";
import { default as payment_45methodsX44gfjHlNwMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/payments/payment-methods.vue?macro=true";
import { default as indexfpWkNybAb1Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/reservations/index.vue?macro=true";
import { default as indexCyhDejo9LtMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/reviews/index.vue?macro=true";
import { default as categoriesu4c9s2jrxCMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/categories.vue?macro=true";
import { default as indexPwFRLBxLJFMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/index.vue?macro=true";
import { default as itemsVYFkvwVEIfMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/items.vue?macro=true";
import { default as modifier_45recipesPO8E1uEhWQMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/modifier-recipes.vue?macro=true";
import { default as _91storeId_93kkhSxqyLaWMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/stores/[storeId].vue?macro=true";
import { default as variant_45recipesmOGCxJTGqTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/variant-recipes.vue?macro=true";
import { default as stockb8oZa4hmCvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock.vue?macro=true";
import { default as bookkeepingEgPlwWacf6Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/bookkeeping.vue?macro=true";
import { default as _91floorId_93yPBKurC6wxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/floors/[floorId].vue?macro=true";
import { default as index5t4fQesk76Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/floors/index.vue?macro=true";
import { default as hardwareKDpK2NAEdsMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/hardware.vue?macro=true";
import { default as indexcSwtOXwKcDMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/index.vue?macro=true";
import { default as indexu4pXyfbTiTMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/kitchen/index.vue?macro=true";
import { default as _91kitchenStationId_93shgkSgcOL8Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue?macro=true";
import { default as stock0d3I9DzcUiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/stock.vue?macro=true";
import { default as _91id_93CoSeqrxErUMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id].vue?macro=true";
import { default as indexXvFBICuJGqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/index.vue?macro=true";
import { default as index26shJeblCvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/colors/index.vue?macro=true";
import { default as _91id_93r4gdNHqRCbMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/folders/[id].vue?macro=true";
import { default as indexxEOOUaHKzkMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/folders/index.vue?macro=true";
import { default as indexIOfPgyBf49Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/index.vue?macro=true";
import { default as logQfXt85kVJ4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/log.vue?macro=true";
import { default as navigationvH6oVImd8lMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/navigation.vue?macro=true";
import { default as _91id_93XkfMNeo8LzMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id].vue?macro=true";
import { default as indexTXio9P9jaiMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/index.vue?macro=true";
import { default as indexKvzgrMfNl7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/profile/index.vue?macro=true";
import { default as download6eOP5E6JdMMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/download.vue?macro=true";
import { default as efficiency3Ka3xocVesMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/efficiency.vue?macro=true";
import { default as indexmZqVchP6n8Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/index.vue?macro=true";
import { default as productionM1mXRJuxbYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/production.vue?macro=true";
import { default as tipsoDTU1yfmvIMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/tips.vue?macro=true";
import { default as indexXBE1e0VnrxMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settings/index.vue?macro=true";
import { default as indexMffViTbUtmMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cancellations/index.vue?macro=true";
import { default as reasonsMUyBlvrIbOMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cancellations/reasons.vue?macro=true";
import { default as cash_45transfersfBnDQ7URsAMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/cash-transfers.vue?macro=true";
import { default as _91id_93Ggouc9WwqYMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/categories/[id].vue?macro=true";
import { default as indexK79oQdJtIKMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/categories/index.vue?macro=true";
import { default as indexepFnOkOG5mMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/index.vue?macro=true";
import { default as indexV2jKf7kuVrMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/index.vue?macro=true";
import { default as logKfug6QnSDvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/log.vue?macro=true";
import { default as ordersWs3Xx0xOFnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/orders.vue?macro=true";
import { default as _91channelSettlementSnapshotId_93oXKBTUEpt7Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue?macro=true";
import { default as indexMqjCA8Bz6CMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/snapshots/index.vue?macro=true";
import { default as _91id_933pmVRTc4LvMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id].vue?macro=true";
import { default as _91id_93TW9Bh9EbgfMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/delivery-providers/[id].vue?macro=true";
import { default as indexOTnLu63dJrMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/delivery-providers/index.vue?macro=true";
import { default as _91id_93VESs009cDUMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/order-providers/[id].vue?macro=true";
import { default as indexLatknzK6iLMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/order-providers/index.vue?macro=true";
import { default as _91id_93u1tfdsJedpMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/orders/[id].vue?macro=true";
import { default as indexVsvoztqBEZMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/orders/index.vue?macro=true";
import { default as bookkeepingoI4PEY3eCkMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/bookkeeping.vue?macro=true";
import { default as channel_45settlementsbi90SCOc7fMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/channel-settlements.vue?macro=true";
import { default as indexrCnuFco5OZMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/index.vue?macro=true";
import { default as logABcOiMo6T2Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/log.vue?macro=true";
import { default as orders3pspLPYvK4Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/orders.vue?macro=true";
import { default as _91reconciliationId_931iFwDM46vnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue?macro=true";
import { default as indexWpSt5aa6AnMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/reconciliations/index.vue?macro=true";
import { default as _91id_93Jx4OEiP8nQMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id].vue?macro=true";
import { default as closing_45errorsawCrDtSUQqMeta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/closing-errors.vue?macro=true";
import { default as indexhpkM9kBXH3Meta } from "/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/index.vue")
  },
  {
    name: "inventory-bundle-choices-id",
    path: "/inventory/bundle-choices/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundle-choices/[id].vue")
  },
  {
    name: "inventory-bundle-choices",
    path: "/inventory/bundle-choices",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundle-choices/index.vue")
  },
  {
    name: "inventory-bundles-id",
    path: "/inventory/bundles/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundles/[id].vue")
  },
  {
    name: "inventory-bundles",
    path: "/inventory/bundles",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/bundles/index.vue")
  },
  {
    name: "inventory-menus-id-categories-menuCategoryId",
    path: "/inventory/menus/:id()/categories/:menuCategoryId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/[id]/categories/[menuCategoryId].vue")
  },
  {
    name: "inventory-menus-id",
    path: "/inventory/menus/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/[id]/index.vue")
  },
  {
    name: "inventory-menus",
    path: "/inventory/menus",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/menus/index.vue")
  },
  {
    name: "inventory-modifiers",
    path: "/inventory/modifiers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/index.vue")
  },
  {
    name: _91id_93at298mGUCDMeta?.name,
    path: "/inventory/modifiers/modifier-groups/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id].vue"),
    children: [
  {
    name: "inventory-modifiers-modifier-groups-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id]/index.vue")
  },
  {
    name: "inventory-modifiers-modifier-groups-id-modifiers-modifierId",
    path: "modifiers/:modifierId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/[id]/modifiers/[modifierId].vue")
  }
]
  },
  {
    name: "inventory-modifiers-modifier-groups",
    path: "/inventory/modifiers/modifier-groups",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/modifiers/modifier-groups/index.vue")
  },
  {
    name: "inventory-products",
    path: "/inventory/products",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/index.vue")
  },
  {
    name: "inventory-products-product-categories-id",
    path: "/inventory/products/product-categories/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/product-categories/[id].vue")
  },
  {
    name: "inventory-products-product-categories",
    path: "/inventory/products/product-categories",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/product-categories/index.vue")
  },
  {
    name: "inventory-products-products-id",
    path: "/inventory/products/products/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/products/[id].vue")
  },
  {
    name: "inventory-products-products",
    path: "/inventory/products/products",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/inventory/products/products/index.vue")
  },
  {
    name: "merchants",
    path: "/merchants",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/merchants.vue")
  },
  {
    name: _91id_933Hm9SVYmhRMeta?.name,
    path: "/point-of-sale/account-configurations/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id].vue"),
    children: [
  {
    name: "point-of-sale-account-configurations-id-accounts-accountId",
    path: "accounts/:accountId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id]/accounts/[accountId].vue")
  },
  {
    name: "point-of-sale-account-configurations-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-account-configurations",
    path: "/point-of-sale/account-configurations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/account-configurations/index.vue")
  },
  {
    name: _91id_93a6TaxShE72Meta?.name,
    path: "/point-of-sale/connections/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id].vue"),
    children: [
  {
    name: "point-of-sale-connections-id-credentials",
    path: "credentials",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id]/credentials.vue")
  },
  {
    name: "point-of-sale-connections-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/[id]/index.vue")
  }
]
  },
  {
    name: "point-of-sale-connections-create-driver",
    path: "/point-of-sale/connections/create/:driver()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/create/[driver].vue")
  },
  {
    name: "point-of-sale-connections-create",
    path: "/point-of-sale/connections/create",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/create/index.vue")
  },
  {
    name: "point-of-sale-connections",
    path: "/point-of-sale/connections",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/connections/index.vue")
  },
  {
    name: "point-of-sale-discounts-discounts-id",
    path: "/point-of-sale/discounts/discounts/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/discounts/[id].vue")
  },
  {
    name: "point-of-sale-discounts-discounts",
    path: "/point-of-sale/discounts/discounts",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/discounts/index.vue")
  },
  {
    name: "point-of-sale-discounts",
    path: "/point-of-sale/discounts",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/discounts/index.vue")
  },
  {
    name: _91id_93GHaKE8FF0GMeta?.name,
    path: "/point-of-sale/employees/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id].vue"),
    children: [
  {
    name: "point-of-sale-employees-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id]/index.vue")
  },
  {
    name: "point-of-sale-employees-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/[id]/log.vue")
  }
]
  },
  {
    name: "point-of-sale-employees",
    path: "/point-of-sale/employees",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/employees/index.vue")
  },
  {
    name: "point-of-sale-images",
    path: "/point-of-sale/images",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/images/index.vue")
  },
  {
    name: "point-of-sale-payments",
    path: "/point-of-sale/payments",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/payments/index.vue")
  },
  {
    name: "point-of-sale-payments-payment-methods",
    path: "/point-of-sale/payments/payment-methods",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/payments/payment-methods.vue")
  },
  {
    name: "point-of-sale-reservations",
    path: "/point-of-sale/reservations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/reservations/index.vue")
  },
  {
    name: "point-of-sale-reviews",
    path: "/point-of-sale/reviews",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/reviews/index.vue")
  },
  {
    name: stockb8oZa4hmCvMeta?.name,
    path: "/point-of-sale/stock",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock.vue"),
    children: [
  {
    name: "point-of-sale-stock-categories",
    path: "categories",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/categories.vue")
  },
  {
    name: "point-of-sale-stock",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/index.vue")
  },
  {
    name: "point-of-sale-stock-items",
    path: "items",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/items.vue")
  },
  {
    name: "point-of-sale-stock-modifier-recipes",
    path: "modifier-recipes",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/modifier-recipes.vue")
  },
  {
    name: "point-of-sale-stock-stores-storeId",
    path: "stores/:storeId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/stores/[storeId].vue")
  },
  {
    name: "point-of-sale-stock-variant-recipes",
    path: "variant-recipes",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stock/variant-recipes.vue")
  }
]
  },
  {
    name: _91id_93CoSeqrxErUMeta?.name,
    path: "/point-of-sale/stores/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id].vue"),
    children: [
  {
    name: "point-of-sale-stores-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/bookkeeping.vue")
  },
  {
    name: "point-of-sale-stores-id-floors-floorId",
    path: "floors/:floorId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/floors/[floorId].vue")
  },
  {
    name: "point-of-sale-stores-id-floors",
    path: "floors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/floors/index.vue")
  },
  {
    name: "point-of-sale-stores-id-hardware",
    path: "hardware",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/hardware.vue")
  },
  {
    name: "point-of-sale-stores-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen",
    path: "kitchen",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/kitchen/index.vue")
  },
  {
    name: "point-of-sale-stores-id-kitchen-kitchen-stations-kitchenStationId",
    path: "kitchen/kitchen-stations/:kitchenStationId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/kitchen/kitchen-stations/[kitchenStationId].vue")
  },
  {
    name: "point-of-sale-stores-id-stock",
    path: "stock",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/[id]/stock.vue")
  }
]
  },
  {
    name: "point-of-sale-stores",
    path: "/point-of-sale/stores",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/stores/index.vue")
  },
  {
    name: "point-of-sale-terminals-colors",
    path: "/point-of-sale/terminals/colors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/colors/index.vue")
  },
  {
    name: "point-of-sale-terminals-folders-id",
    path: "/point-of-sale/terminals/folders/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/folders/[id].vue")
  },
  {
    name: "point-of-sale-terminals-folders",
    path: "/point-of-sale/terminals/folders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/folders/index.vue")
  },
  {
    name: _91id_93XkfMNeo8LzMeta?.name,
    path: "/point-of-sale/terminals/terminals/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id].vue"),
    children: [
  {
    name: "point-of-sale-terminals-terminals-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/index.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/log.vue")
  },
  {
    name: "point-of-sale-terminals-terminals-id-navigation",
    path: "navigation",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/[id]/navigation.vue")
  }
]
  },
  {
    name: "point-of-sale-terminals-terminals",
    path: "/point-of-sale/terminals/terminals",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/point-of-sale/terminals/terminals/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/profile/index.vue")
  },
  {
    name: "reports-download",
    path: "/reports/download",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/download.vue")
  },
  {
    name: "reports-efficiency",
    path: "/reports/efficiency",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/efficiency.vue")
  },
  {
    name: "reports",
    path: "/reports",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/index.vue")
  },
  {
    name: "reports-production",
    path: "/reports/production",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/production.vue")
  },
  {
    name: "reports-tips",
    path: "/reports/tips",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/reports/tips.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settings/index.vue")
  },
  {
    name: "settlement-cancellations",
    path: "/settlement/cancellations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cancellations/index.vue")
  },
  {
    name: "settlement-cancellations-reasons",
    path: "/settlement/cancellations/reasons",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cancellations/reasons.vue")
  },
  {
    name: "settlement-cash-transfers-cash-transfers",
    path: "/settlement/cash-transfers/cash-transfers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/cash-transfers.vue")
  },
  {
    name: "settlement-cash-transfers-categories-id",
    path: "/settlement/cash-transfers/categories/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/categories/[id].vue")
  },
  {
    name: "settlement-cash-transfers-categories",
    path: "/settlement/cash-transfers/categories",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/categories/index.vue")
  },
  {
    name: "settlement-cash-transfers",
    path: "/settlement/cash-transfers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/cash-transfers/index.vue")
  },
  {
    name: _91id_933pmVRTc4LvMeta?.name,
    path: "/settlement/channel-settlements/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id].vue"),
    children: [
  {
    name: "settlement-channel-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/index.vue")
  },
  {
    name: "settlement-channel-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/log.vue")
  },
  {
    name: "settlement-channel-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots-channelSettlementSnapshotId",
    path: "snapshots/:channelSettlementSnapshotId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/snapshots/[channelSettlementSnapshotId].vue")
  },
  {
    name: "settlement-channel-settlements-id-snapshots",
    path: "snapshots",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/channel-settlements/[id]/snapshots/index.vue")
  }
]
  },
  {
    name: "settlement-delivery-providers-id",
    path: "/settlement/delivery-providers/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/delivery-providers/[id].vue")
  },
  {
    name: "settlement-delivery-providers",
    path: "/settlement/delivery-providers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/delivery-providers/index.vue")
  },
  {
    name: "settlement-order-providers-id",
    path: "/settlement/order-providers/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/order-providers/[id].vue")
  },
  {
    name: "settlement-order-providers",
    path: "/settlement/order-providers",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/order-providers/index.vue")
  },
  {
    name: "settlement-orders-id",
    path: "/settlement/orders/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/orders/[id].vue")
  },
  {
    name: "settlement-orders",
    path: "/settlement/orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/orders/index.vue")
  },
  {
    name: _91id_93Jx4OEiP8nQMeta?.name,
    path: "/settlement/store-settlements/:id()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id].vue"),
    children: [
  {
    name: "settlement-store-settlements-id-bookkeeping",
    path: "bookkeeping",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/bookkeeping.vue")
  },
  {
    name: "settlement-store-settlements-id-channel-settlements",
    path: "channel-settlements",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/channel-settlements.vue")
  },
  {
    name: "settlement-store-settlements-id",
    path: "",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/index.vue")
  },
  {
    name: "settlement-store-settlements-id-log",
    path: "log",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/log.vue")
  },
  {
    name: "settlement-store-settlements-id-orders",
    path: "orders",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/orders.vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations-reconciliationId",
    path: "reconciliations/:reconciliationId()",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/reconciliations/[reconciliationId].vue")
  },
  {
    name: "settlement-store-settlements-id-reconciliations",
    path: "reconciliations",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/[id]/reconciliations/index.vue")
  }
]
  },
  {
    name: "settlement-store-settlements-closing-errors",
    path: "/settlement/store-settlements/closing-errors",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/closing-errors.vue")
  },
  {
    name: "settlement-store-settlements",
    path: "/settlement/store-settlements",
    component: () => import("/var/www/sites/portal.pos2.halifax.dk/releases/20241211133858/pages/settlement/store-settlements/index.vue")
  }
]