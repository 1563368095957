import { useAuth } from "~/stores/auth";

export const useApi = async (path, options = {}) => {
  const auth = useAuth();
  const runtimeConfig = useRuntimeConfig();

  const url = path.startsWith("https://")
    ? path
    : runtimeConfig.public.apiUrl + path;

  const headers = {
    Accept: "application/json",
    Authorization: "Bearer " + auth.token,
    Merchant: auth.merchantAccess.attributes.merchant_id,
    ...options.headers,
  };

  try {
    return await $fetch(url, {
      ...options,
      headers,
    });
  } catch (error) {
    if (error.statusCode === 401) {
      return auth.logout();
    }

    throw error;
  }
};
