import { defineStore } from "pinia";

export const useMerchant = defineStore("merchant", {
  state: () => ({
    merchant: null,
  }),

  actions: {
    async load() {
      const response = await useApi("/api/v1/merchant/merchant");

      this.merchant = response.data;
    },
  },

  getters: {
    hasReservationConfiguration: (state) =>
      state.merchant.attributes.has_reservation_configuration,
    hasReviewConfigurations: (state) =>
      state.merchant.attributes.has_review_configurations,
    hasStockConfiguration: (state) =>
      state.merchant.attributes.has_stock_configuration,
  },
});
